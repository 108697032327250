<template>
  <div>
    <el-button :type="buttonType" :size="buttonSize" :icon="buttonIcon" :disabled="buttonDisabled" @click="open" v-if="showButton">{{ buttonText }}</el-button>

    <el-dialog :visible.sync="dialog" :title="title" append-to-body :close-on-click-modal="false" @closed="handleClosed" width="1330px">
      <div class="fc-g" style="margin-bottom: 12px" v-if="tip">{{ tip }}</div>
      <div class="h head-container">
        <quick-select v-model="query.shopId" url="api/shop" placeholder="门店" clearable filterable @change="toQuery" value-field="id" display-field="shopName" class="filter-item" style="width: 160px" />
        <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="交货日期起" end-placeholder="交货日期止" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" style="width: 220px" @change="toQuery" />
        <el-input v-model="query.keywords" class="filter-item" :maxlength="20" placeholder="销售单号/合同号/电话号码" @keypress.enter.native="toQuery" style="width: 180px" />
        <el-select v-model="query.isPurchased" filterable clearable placeholder="采购状态" class="filter-item" style="width: 90px;" @change="toQuery">
          <el-option label="已采购" :value="true" />
          <el-option label="未采购" :value="false" />
        </el-select>
        <el-select v-model="query.statusList" filterable clearable placeholder="订单状态" class="filter-item" style="width: 150px" @change="toQuery" multiple collapse-tags default-first-option>
          <el-option v-for="item in status" :key="item.key" :label="item.label" :value="item.key" />
        </el-select>
        <quick-cascader class="filter-item" v-model="query.seriesId" url="api/series/tree" :params="{enabled: true}" root-key check-strictly filterable clearable placeholder="系列" expand-trigger="hover" style="width: 140px;" @change="toQuery" />
        <el-input v-model="query.goodsName" class="filter-item" :maxlength="40" placeholder="产品名称" @keypress.enter.native="toQuery" style="width: 150px" />
        <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click.stop="toQuery">搜索</el-button>
        <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
      </div>

      <el-table ref="list" :data="data" border highlight-current-row empty-text="没有相关零售单商品" max-height="480" row-key="id" :default-sort="{prop: 'deliveryDate', order: 'ascending'}" @sort-change="handleSort" @selection-change="handleSelectionChange" v-loading="loading">
        <el-table-column type="selection" width="35" :selectable="checkSelectable" reserve-selection />
        <el-table-column label="采购状态" width="80">
          <template slot-scope="scope">
            <dot :type="scope.row.isPurchased ? 'gray' : 'success'" same>{{scope.row.isPurchased ? '已采购' : '未采购'}}</dot>
          </template>
        </el-table-column>
        <el-table-column prop="formCode" label="销售单号" width="130" sortable="custom" />
        <el-table-column prop="contractNo" label="合同号" width="100" show-overflow-tooltip />
        <el-table-column prop="status" label="订单状态" width="100" align="center">
          <template slot-scope="scope">
            <el-tag :type="status[scope.row.status].type">{{status[scope.row.status].label}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="客户" width="140" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.customerName }}({{scope.row.customerPhone}})</template>
        </el-table-column>
        <el-table-column prop="erpCode" label="ERP编码" width="130" />
        <el-table-column prop="goodsName" label="商品" min-width="180" show-overflow-tooltip sortable="custom">
          <div style="line-height: 1.5" slot-scope="scope">
            <div>{{ scope.row.goodsName }}</div>
            <div class="fc-g">{{ $goodsSpecConvert(scope.row.specs) }}</div>
          </div>
        </el-table-column>
        <el-table-column prop="quantity" label="数量" align="center" width="70" />
        <el-table-column prop="deliveryDate" label="交货日期" align="center" width="100" :formatter="r => { return new Date(r.deliveryDate).format('yyyy/MM/dd'); }" sortable="custom" />
        <el-table-column prop="sendCount" label="已发货" align="center" width="70" />
        <el-table-column prop="info" label="备注" width="130" show-overflow-tooltip />
      </el-table>
      <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />

      <div style="margin-top: 12px" v-if="multiple">
        <el-button type="primary" :disabled="loading || !selection || !selection.length" @click="handleSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import initData from "@/mixins/initData";

export default {
  mixins: [initData],
  props: {
    showButton: {
      type: Boolean,
      default: true,
    },
    buttonType: {
      type: String,
      default: "primary",
    },
    buttonIcon: String,
    buttonSize: {
      type: String,
      default: "small",
    },
    buttonText: {
      type: String,
      default: "选择零售单商品",
    },
    buttonLoading: Boolean,
    buttonDisabled: Boolean,
    multiple: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "选择零售单商品",
    },
    keepQuery: Boolean,
    tip: String,
    excludeKeys: Array,
    supplier: String | Number,
  },
  data() {
    return {
      dialog: false,
      inited: false,
      selection: null,
      current: null,
      loading: false,
      sort: "deliveryDate,asc",
      status: {
        // input: { key: "input", label: "录入状态", type: "info" },
        // submit: { key: "submit", label: "提交状态", type: "" },
        // auditing: { key: "auditing", label: "审核中", type: "" },
        pass: { key: "pass", label: "审核通过", type: "success" },
        // fail: { key: "fail", label: "审批未通过", type: "danger" },
        // callback: { key: "callback", label: "退回修改", type: "info" },
        sendPart: { key: "sendPart", label: "部分已发货", type: "success" },
        sended: { key: "sended", label: "已发货", type: "success" },
        closedPart: { key: "closedPart", label: "部分关闭", type: "info" },
        // closed: { key: "closed", label: "已关闭", type: "info" },
        // cancel: { key: "cancel", label: "已取消", type: "info" },
      },
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      query: {
        shopId: null,
        dateRange: null,
        keywords: null,
        statusList: [],
        isPurchased: false,
      },
    };
  },
  methods: {
    checkSelectable(row) {
      let enable = row.allowPurchase && row.quantity - row.cancelCount > 0;
      if (enable && this.excludeKeys && this.excludeKeys.length) {
        enable =
          this.excludeKeys.findIndex((ek) => {
            return row.id + "_" + row.goodsId === ek;
          }) < 0;
      }
      return enable;
    },
    handleSort(sort) {
      if (sort.order == null) this.sort = "";
      else
        this.sort =
          sort.prop + "," + (sort.order === "ascending" ? "asc" : "desc");
      this.toQuery();
    },
    checkItemStatus(scope) {
      return scope.row.quantity - scope.row.sendCount > 0
        ? ""
        : "common-gift-goods-row";
    },
    beforeInit() {
      this.url = "api/shop/orderFormFull/purchase";
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.deliveryDateMin = query.dateRange[0];
        query.deliveryDateMax = query.dateRange[1];
      }
      delete query.dateRange;
      if (!query.statusList || query.statusList.length == 0) {
        query.statusList = ["pass", "sendPart", "closedPart", "sended"];
      }
      this.params = Object.assign({ sort: this.sort }, query);
      this.params.supplier = this.supplier;
      this.inited = true;
      return true;
    },

    handleSelectionChange(s) {
      if (this.multiple) {
        this.selection = s;
      }
    },
    open() {
      this.dialog = true;
      // if (!this.keepQuery) {
      // for (let k in this.query) {
      //   if (this.query.hasOwnProperty(k)) {
      //     this.query[k] = null;
      //   }
      // }
      this.toQuery();
      // }
    },
    handleClosed() {
      this.selection = null;
      this.$refs.list && this.$refs.list.setCurrentRow();
      this.$refs.list && this.$refs.list.clearSelection();
    },
    handleSubmit() {
      if (this.selection && this.selection.length) {
        let arr = this.selection.map((o) => {
          return {
            id: o.goodsId,
            specs: o.specs,
            name: o.goodsName,
            code: o.code,
            erpCode: o.erpCode,
            purchaseLimit: false,
            count: o.quantity,
            source: [o.id],
            retailFormCode: [o.formCode],
            retailContractNo: [o.contractNo],
            shop: [o.shopName],
            info: o.info,
            customer: [o.customerName],
          };
        });
        this.$emit("submit", arr);
        this.$refs.list && this.$refs.list.clearSelection();
        this.dialog = false;
      }
    },
  },
};
</script>